.driver-popover.driverjs-theme {
    background-color: #FFF7ED;
    color: #000;
  }
  
  /* .driver-popover.driverjs-theme .driver-popover-title {
    font-size: 20px;
  } */
  
  /* .driver-popover.driverjs-theme .driver-popover-title,
  .driver-popover.driverjs-theme .driver-popover-description, */
  .driver-popover.driverjs-theme .driver-popover-progress-text {
    color: #413631;
  }
  
  .driver-popover.driverjs-theme button {
    text-align: center;
    background-color: #413631;
    color: #ffffff;
    text-shadow: none;
    border-radius: 6px;
  }
  
  /* .driver-popover.driverjs-theme button:hover {
    background-color: #000;
    color: #ffffff;
  }
   */
  /* .driver-popover.driverjs-theme .driver-popover-navigation-btns {
    justify-content: space-between;
    gap: 3px;
  } */
  
  .driver-popover.driverjs-theme .driver-popover-close-btn {
    color: #000;
    background-color:#FFF7ED;
  }
  
  .driver-popover.driverjs-theme .driver-popover-close-btn:hover {
    color: #9b9b9b;
  }
  
  /* .driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
    border-left-color: #fde047;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
    border-right-color: #fde047;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
    border-top-color: #fde047;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
    border-bottom-color: #fde047;
  } */